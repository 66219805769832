

.App {
  width: 90%;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  display: block;
}

.root{
    max-width: 100vw;
    overflow: hidden;
}

.link{
    text-decoration: none;
}

  
.App::-webkit-scrollbar {
  display: none;
}

.search-div{
  width: 90%;
  margin: 20px auto;
  border-radius: 6px;
  overflow: hidden;
}

.search-button-div{
  width: 60%;
  min-width: 140ox;
  max-width: 240px;
  height: 54px;
  line-height: 54px;
  padding: 0;
  margin: 0 auto;
  color: #282c34;
  font-weight: 700;
  font-size: 18px;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
}

.search-button-div:hover{
  cursor: pointer;
}

.search-level-div{
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 10px;
  margin: 0;
  color: #282c34;
  font-weight: 700;
  font-size: 18px;
  box-sizing: border-box;
  display: flex;
}

.search-level-div-title{
  width: 20%;
  min-width: 80px;
  max-width: 100px;
  text-align: center;
}

.search-level-div-option{
  width: 80%;
  height: 54px;
  overflow: hidden;
  overflow-x: scroll;
  display: inline-flex;
}

.search-level-div-option::-webkit-scrollbar {
  display: none;
}

.search-level-div-option-item{
  min-width: 48px;
  flex: 0 0 auto;
  text-align: center;
  font-size: 1rem;
  padding: 0 10px;
  color: #888;
  font-weight: 500;
  cursor: pointer;
}

.search-level-div-option-item-selected{
  min-width: 48px;
  flex: 0 0 auto;
  text-align: center;
  font-size: 1rem;
  padding: 0 10px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

.product-div-list{
  padding: 0 10px;
  box-sizing: border-box;
  margin: 10px auto;
  margin-top: 10px;
  min-height: 800px;
}


.product-div:hover{
  background-color: #f5f5f5;
  cursor: pointer;
}

.product-div-info{
  width: 75%;
  height: 100%;
  padding: 18px 0;
  box-sizing: border-box;
  text-align: left;
}

.product-div-info-title{
  width: 100%;
  height: 78px;
  line-height: 24px;
  padding: 0 10px;
  margin: 0;
  color: #282c34;
  font-weight: 700;
  font-size: 18px;
  box-sizing: border-box;
}

.product-div-info-subtitle{
  width: 100%;
  height: 24px;
  line-height: 22px;
  padding: 0 10px;
  margin: 0;
  color: #282c34;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-div-info-market{
  width: 100%;
  height: 24px;
  line-height: 22px;
  padding: 0 10px;
  margin: 0;
  color: #282c34;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-div-info-price{
  width: 100%;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  margin: 0;
  color: #282c34;
  font-weight: 700;
  font-size: 20px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-div-image{
  width: 50%;
  min-width: 90px;
  max-width: 130px;
  height: 100%;
  border-radius: 6px;
  padding: 10px 0;
  box-sizing: border-box;
  text-align: center;
  display:flex;
  justify-content:center;
  align-items:center;
    object-fit: contain;
}

.product-div-img{
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 6px;
}

.sell-div{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #282c34;
  border-bottom: 1px solid #282c34;
  margin: 0;
}