.Header {
    width: 90%;
    min-width: 280px;
    max-width: 1200px;
    background-color: #fff;
    height: 60px;
    padding: 0px 0px;
    box-sizing: border-box;
    text-align: left;
    margin: auto;
}

.Header-main {
    width: 100%;
    height: 48px;
    padding: 0px;
    margin: 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.Header-logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Header-logo-ul {
    max-width: 260px;
    padding: 0;
    margin: 0;
    max-height: 48px;
}

.header-logo {
    margin-top: 2px;
    height: 48px;
}

.Header-menu {
    min-width: 30px;
    width: 50px;
    max-width: calc(100% - 260px);
    height: 48px;
    line-height: 48px;
    margin: 0px;
    border-radius: 2px;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

.Header-menu:hover {
    background-color: #f5f5f5;
}