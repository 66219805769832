.product-info-div{
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.product-info-img{
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    object-fit: cover;
}

.product-list-div{
    width: 100%;
    max-height: 100vh;
}

.page-div{
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
}

.product-div{
    width: 100%;
    height: 140px;
    display: inline-flex;
    justify-content: left;
    align-items: center;
    padding: 0 4px;
    margin: 0;
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;
  }
.product-title{
    width: 100%;
    height: 42px;
    line-height: 42px;
    padding: 0px;
    margin: 0;
    color: #282c34;
    font-weight: 700;
    font-size: 24px;
    box-sizing: border-box;
    border-radius: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-price{
    width: 100%;
    height: 28px;
    line-height: 28px;
    padding: 0px 2px;
    margin: 0;
    color: #282c34;
    font-weight: 500;
    font-size: 22px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 22px;
    font-weight: 600;
}

.product-info-text{
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 2px 4px 4px 2px rgba(0,0,0,0.2);
}

.product-info-select-div{
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: content-box;
    border: 1px solid #282c34;
    border-radius: 2px;
    margin-top: 16px;
}


.product-info-select-button{
    min-width: 24px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: none;
    font-size: 18px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: white;
}

.product-info-select-button-selected{
    min-width: 24px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: none;
    border-bottom: 1px solid #282c34;
    color: white;
    background-color: #111;
    font-size: 18px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-list-title{
    width: 100%;
    height: 72px;
    line-height: 72px;
    padding: 0 10px;
    margin: 0;
    margin-bottom: 10px;
    color: #282c34;
    font-weight: 600;
    font-size: 24px;
    box-sizing: border-box;
    border-radius: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 2px 4px 4px 2px rgba(0,0,0,0.2);
}

.product-list-item-div{
    width: 100%;
    box-shadow: 2px 4px 4px 2px rgba(0,0,0,0.2);
    border-radius: 6px;
    padding: 2px 4px;
    margin: 10px 0;
    box-sizing: border-box;
}

.product-list-item-text{
    width: 100%;
    height: 48px;
    max-height: 120px;
    line-height: 48px;
    padding: 0 10px;
    margin: 0;
    color: #282c34;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-list-item-price{
    width: 100%;
    height: 60px;
    max-height: 120px;
    line-height: 60px;
    padding: 0 12px;
    margin: 0;
    color: #282c34;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-list-item-total{
    width: 100%;
    height: 54px;
    max-height: 120px;
    line-height: 54px;
    padding: 0 12px;
    margin: 0;
    color: #282c34;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top: 1px solid #282c34;
}

.product-list-pic{
    width: calc(100% - 20px);
    height: 120px;
    margin: 10px auto;
    display: flex;
    overflow-x: scroll;
    box-sizing: border-box;
}

.product-list-pic::-webkit-scrollbar{
    display: none;
}

.product-list-pic-img{
    height: 120px;
    object-fit: cover;

}

.product-list-info{
    width: 100%;
    min-height: 24px;
    line-height: 24px;
    font-size: 18px;
    padding: 0 10px;
}

.product-list-button{
    width: 98%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: none;
    border-radius: 6px;
    background-color: #ceffce;
    box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
    margin: 8px auto;
}

.product-add-button{
    width: 98%;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border: none;
    border-radius: 6px;
    background-color: #ceffce;
    box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
    margin: 8px auto;
}



.product-order-button{
    width: 98%;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border: none;
    border-radius: 6px;
    background-color: #ceffce;
    box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
    margin: 8px auto;
}

.product-list-quantity{
    display: flex;
}

.quantity-button{
    width: 22px;
    height: 22px;
    font-size: 22px;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
    border: none;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: white;
    margin: 13px 4px;
}


@media screen and (max-width: 1240px){
    .product-div{
        width: 100%;
    }
    
    .page-div{
        width: 98%;
    }

    .App{
        width: 98% !important;
    }
}

@media screen and (min-width: 1240px){
    .page-div{
        width: 90%;
        display: flex;
    }
    .product-div{
        width: 100%;
        max-width: 540px;
    }
    .product-info-div{
        max-width: calc(100% - 540px);
        margin-bottom: 0px;
        box-shadow: none;
    }
    .product-info-img{
        width: 100%;
        object-fit: cover;
    }
    .product-list-div{
        width: 100%;
        max-width: 540px;
        max-height: 100vh;
    }
    
    .product-info-text{
        width: calc(100% - 6px) !important;
        padding: 10px 10px;
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: 2px 4px 4px 2px rgba(0,0,0,0.2);
    }

}
