.buy-title{
    margin: 6px 0;
    text-align: center;
}

.buy-sub{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.buy-ul{
    padding: 0;
    list-style: none;
}

.buy-li{
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    padding: 2px 0;
    margin: 4px 0;
    border-bottom: 1px solid black;
}

.buy-circle-check{
    width: 30px;
    height: 30px;
    margin: 5px 10px 5px 2px;
    border-radius: 50%;
    border: 2px solid rgba(76, 175, 80, .8);
    box-sizing: border-box;
    color: white;
    text-align: center;
    line-height: 36px;
    display: inline-block;
    
    .buy-check1{
        width: 13px;
        height: 4px;
        margin: 10px 0 0 11px;
        background-color: rgba(76, 175, 80, .8);
        transform: rotate(-45deg);
    }

    .buy-check2{
        width: 10px;
        height: 4px;
        margin: 0px 4px;
        background-color: rgba(76, 175, 80, .8);
        transform: rotate(45deg);
    }
}

.buy-circle-stop{
    width: 30px;
    height: 30px;
    margin: 5px 10px 5px 2px;
    border-radius: 50%;
    border: 2px solid rgba(230, 120, 120, 1);
    box-sizing: border-box;
    color: white;
    text-align: center;
    line-height: 36px;
    display: inline-block;
    
    .buy-stop1{
        position: relative;
        top: 11px;
        left: 3px;
        width: 20px;
        height: 4px;
        background-color: rgba(230, 120, 120, 1);
        transform: rotate(-45deg);
    }

    .buy-stop2{
        position: relative;
        top: 7px;
        left: 3px;
        width: 20px;
        height: 4px;
        background-color: rgba(230, 120, 120, 1);
        transform: rotate(45deg);
    }
}

.buy-circle-wait{
    width: 30px;
    height: 30px;
    margin: 5px 10px 5px 2px;
    border-radius: 50%;
    border: 2px solid rgba(120, 120, 120, 1);
    box-sizing: border-box;
    color: white;
    text-align: center;
    line-height: 36px;
    display: flex;
    
    .buy-wait1{
        position: relative;
        top: 10.5px;
        left: 2.5px;
        width: 5.5px;
        height: 5.5px;
        border-radius: 50%;
        background-color: rgba(120, 120, 120, 1);
    }

    .buy-wait2{
        position: relative;
        top: 10.5px;
        left: 5px;
        width: 5.5px;
        height: 5.5px;
        border-radius: 50%;
        background-color: rgba(120, 120, 120, 1);
    }

    .buy-wait3{
        position: relative;
        top: 10.5px;
        left: 7.5px;
        width: 5.5px;
        height: 5.5px;
        border-radius: 50%;
        background-color: rgba(120, 120, 120, 1);
    }
}



.fail-stopsign {
    width: 80px;
    height: 115px;
    margin: 40px auto 0 auto;
    
    .stop-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #d67878;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: stop-rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .stop-icon-line {
            height: 5px;
            background-color: #d67878;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.stop-line-tip {
                top: 38px;
                left: 14px;
                width: 54px;
                transform: rotate(45deg);
                animation: stop-icon-line-tip 0.75s;
            }
            
            &.stop-line-long {
                top: 38px;
                right: 14px;
                width: 54px;
                transform: rotate(-45deg);
                animation: stop-icon-line-long 0.75s;
            }
        }
        
        .stop-icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(230, 120, 120, 0.8);
        }
        
        .stop-icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes stop-icon-line-tip {
    0% {
        width: 0;
        right: 14px;
        top: 19px;
    }
    30% {
        width: 0;
        left: 14px;
        top: 19px;
    }
    42% {
        width: 60px;
        left: 14px;
        top: 38px;
    }
    48% {
        width: 52px;
        left: 14px;
        top: 38px;
    }
    100% {
        width: 52px;
        left: 14px;
        top: 38px;
    }
}

@keyframes stop-icon-line-long {
    0% {
        width: 0;
        left: 14px;
        top: 19px;
    }
    50% {
        width: 0;
        right: 14px;
        top: 19px;
    }
    62% {
        width: 60px;
        right: 14px;
        top: 38px;
    }
    68% {
        width: 52px;
        right: 14px;
        top: 38px;
    }
    100% {
        width: 52px;
        right: 14px;
        top: 38px;
    }
}










 .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 40px auto 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes stop-rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    20%{
        transform: rotate(-765deg);
    }
    100% {
        transform: rotate(-765deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}