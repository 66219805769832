.category-div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 4px 12px;
    margin: 0;
    box-sizing: border-box;
}

.category-ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
}

.category-li{
    padding: 5px 8px;
    margin: 4px 4px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    background-color: #e2e2e2;
}

.category-li-selected{
    padding: 5px 8px;
    margin: 4px 4px;
    border-radius: 4px;
    font-weight: 500;
    color: white;
    font-size: 16px;
    background-color: #6e6e6e;
}