.menu-user{
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 0px 0px 2px;
}

.menu-profile{
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 4px 6px 4px 2px;
}

.menu-name{
    width: 100%;
    height: 28px;
    line-height: 28px;
    padding: 0 2px;
    margin: 0;
    color: #282c34;
    font-weight: 600;
    font-size: 18px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-logout{
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0 2px;
    margin: 0;
    color: #282c34;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-div{
    width: 100%;
    padding: 12px 10px;
    margin: 18px 0;
    color: #282c34;
    font-weight: 600;
    box-sizing: border-box;
    border-radius: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 2px 4px 4px 2px rgba(0,0,0,0.2);
    text-decoration: none;
}

.menu-title{
    width: 100%;
    height: 28px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: #282c34;
    font-weight: 600;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-tag{
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0 2px;
    margin: 0;
    color: #282c34;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-price{
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
    color: #282c34;
    font-weight: 500;
    font-size: 18px;
    box-sizing: border-box;
}

.menu-status{
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
    color: #282c34;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-info{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 0px 0px 2px;
}

.menu-currency{
    height: 22px;
    padding: 5px 8px;
    margin: 0px 4px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    box-shadow: 2px 4px 4px 2px rgba(0,0,0,0.2);
}